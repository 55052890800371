import React from "react";

import { COUNTRY_DETAILS } from "./CountryDetailsData";

const Country = ({ countryId }) => {
  const countryData = COUNTRY_DETAILS[countryId] ?? [];

  return (
    <>
      {countryData && (
        <div class="education-main-section">
          <div class="container">
            <div class="row">
              <div class="col-md-9">
                <figure class="education-course-thumb">
                  <img src={countryData?.image} alt="Guiguidanceplusni" />
                  <figcaption>
                    <h2>{countryData?.pageTitle}</h2>
                  </figcaption>
                </figure>
                <div class="">
                  <p className="text-justify">
                    <span dangerouslySetInnerHTML={{ __html: countryData?.pageDesc }} />
                  </p>
                </div>
                {countryData?.pageContent?.map((c) => (
                  <>
                    <div className="education-section-heading">
                      <h2>{c?.heading}</h2>
                    </div>
                    <div className="education-course-features">
                      <p className="text-justify">
                        <span dangerouslySetInnerHTML={{ __html: c?.content }} />
                      </p>
                    </div>
                  </>
                ))}
              </div>
              <aside class="col-md-3 education-sidebar">
                <div class="widget widget_featured_courses">
                  <ul>
                    {COUNTRY_DETAILS.map((country) => (
                      <li>
                        <figure>
                          <a href={country?.link}>
                            <img src={country?.image} alt={country?.pageTitle} />
                            <i class="fa fa-link"></i>
                          </a>
                          <figcaption>
                            <h6>
                              <a href={country?.link}>{country?.pageTitle}</a>
                            </h6>
                          </figcaption>
                        </figure>
                      </li>
                    ))}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Country;
