import coursesGrid1 from "../extra-images/country/canada-1.jpg";
import coursesGrid2 from "../extra-images/country/uk.jpg";
import coursesGrid3 from "../extra-images/country/france.jpg";
import coursesGrid4 from "../extra-images/country/germany.jpg";
import coursesGrid5 from "../extra-images/country/dubai.jpg";
import coursesGrid6 from "../extra-images/country/ireland.jpg";
import coursesGrid7 from "../extra-images/country/malaysia.jpg";
import coursesGrid8 from "../extra-images/country/usa.jpg";
import coursesGrid9 from "../extra-images/country/australia.jpg";
import coursesGrid10 from "../extra-images/country/singapore.jpg";
import coursesGrid11 from "../extra-images/country/new-zealand.jpg";
import coursesGrid12 from "../extra-images/country/sweden.jpg";
import coursesGrid13 from "../extra-images/country/finland.jpg";
import coursesGrid14 from "../extra-images/country/denmark.jpg";
import coursesGrid15 from "../extra-images/country/malta.jpg";
import coursesGrid16 from "../extra-images/country/estonia.jpg";
import coursesGrid17 from "../extra-images/country/lithuania.jpg";
import coursesGrid18 from "../extra-images/country/netherland.jpg";
import coursesGrid19 from "../extra-images/country/poland.jpg";
import coursesGrid20 from "../extra-images/country/norway.jpg";

let countryDetails = [];
countryDetails[1] = {
  name: "Study in Canada",
  image: coursesGrid1,
  link: "/study-in-canada",
  pageTitle: "Study in Canada: The Perks",
  pageDesc:
    "<h6>At a Glance</h6><p>Are you considering pursuing your further education in Canada? Are you curious to find out more about your overseas study options? Guidance Plus is available to assist. We have over ten years of experience and are experts at assisting students just like you in navigating the challenges of studying in Canada. Our counsellors are prepared to offer you whatever information you require, including information on course alternatives, college options, cost schedules, and application procedures. Make your dream of attending university in Canada a reality by getting in touch with us for individualized advice!</p><h6>The perks</h6><p>The world’s second-largest country Canada is one of the favorite destinations for study abroad aspirants. It is one of the safest countries in the world. The country offers several cutting-edge facilities to the students who come to study in Canada. Moreover, it’s one of the most industrialized countries in the world, opening up greater opportunities for students. The country possesses a diverse and all-inclusive environment welcoming students from around the globe. The country ranks 3rd for the best quality of life. The country tries to offer the same sort of rights and facilities to international students just like that are available to a native.</p>",
  pageContent: [
    {
      heading: "Some additional benefits",
      content: `<ol>
        <li>Qualifications are valued around the world.</li>
        <li>Affordable education.</li>
        <li>Multicultural society.</li>
        <li>Healthy and safe communities.</li>
        <li>Possibility of paid internships while studying.</li>
        <li>Post-study work visa</li>
        <li>Good job opportunities.</li>
        <li>Fast-track PR options</li>
        <li>Scholarship availability</li>
        <li>Part-time opportunities</li>
        </ol>`,
    },
    {
      heading: "The Procedures",
      content: `<p>International students can apply to Canada in three main intakes: September, January, and May. Apart from the aforementioned significant intakes, there are a handful of smaller ones, with restricted openings between July and October. This implies that you have a variety of possibilities to fulfil your desire of attending a Canadian university for higher education.</p>
      <ol>
          <li>Choose a course and an institution.</li>
          <li>Application process</li>
          <li>Documentation and fee submission</li>
          <li>Medical examination</li>
          <li>Visa proceedings</li>
          <li>Find accommodation</li>
          </ol>`,
    },
    {
      heading: "Universities recommended by us.",
      content: "",
    },
    {
      heading: "Am I eligible for scholarships?",
      content:
        "<p>If you're struggling with budgetary issues in your arrange to study in Canada, there's no need to stress around those. Numerous grants are accessible for understudies arranging to ponder in Canada. There are grants and budgetary help given by distinctive teach and organizations. All you have got to do is interface with a perfect think about overseas organization to help you in your methods.</p>",
    },
    {
      heading: "Prominent scholarships",
      content: `
      <ol>
        <li>Canadian Commonwealth Scholarship and Fellowship Plan</li>
        <li>Ontario Graduate Scholarship Program</li>
        <li>Dick Martin Scholarship Award</li>
        <li>BeArt Presets Academic Scholarship</li>
        <li>Education Future International Scholarship</li>
        <li>Global Citizen Scholarship</li>
        <li>Narotam Sekhsaria Scholarship</li>
        <li>International Peace Scholarship for Women</li>
      </ol>
      <p>There are different types of scholarships for international students in Canada like, merit-based scholarships, need-based scholarships, athletic scholarships, subject-specific scholarships, regional scholarships, government-funded scholarships, college or university-funded scholarships, and so on. The University of Toronto scholarships for Indian students, McGill University Scholarship, the University of British Columbia scholarships for international students, and The University of Alberta Scholarships are a few of the scholarships provided by certain institutions. Quebec Provincial Government Scholarship is an example of a scholarship that is provided to students in certain provinces.</p>`,
    },
    {
      heading: "Connect with us",
      content:
        "<p>with over 10 years of experience. We help students achieve their dreams of studying abroad, offering expert guidance and support every step of the way.</p>",
    },
  ],
};

countryDetails[2] = {
  name: "Study in UK",
  image: coursesGrid2,
  link: "/study-in-uk-agency",
  pageTitle: "Study in UK",
  pageDesc: `<h6>At a Glance</h6>
    <p>The United Kingdom, made up of England, Wales, Northern Ireland, and Scotland, is renowned for its world-class higher education. With a rich variety of programs in fields like Arts, Culture, Religion, Fashion, Engineering, Management, Hospitality and Tourism, Medicine, and Architecture, the UK attracts thousands of international students each year. Prestigious universities across the UK offer the ideal environment to advance your academic journey.</p>
    <p>Partner with Guidance Plus,  one of the leading UK education consultants, to secure your spot at one of these esteemed institutions and take the first step toward your future.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `
      <ol>
        <li>The UK offers a wide range of courses tailored to career aspirations.</li>
        <li>It has over 100 years of education expertise.</li>
        <li>Free health insurance for students</li>
        <li>A two-year post-study work visa is available after completing a program.</li>
        <li>Courses often include internships and sandwich placements.</li>
        <li>UK degrees are internationally recognized.</li>
        <li>Most universities are government-owned, offering a high volume of options.</li>
        <li>International students can work 20 hours per week during term time and 40 hours during vacations.</li>
        <li>Highest living standards</li>
      </ol>`,
    },
    {
      heading: "Requirements",
      content: `<p>Generally, IELTS is required for studying in the UK, but there are alternative options available for demonstrating English proficiency:</p>
        <ol>
        <li>English Medium Education: Some universities accept a Medium of Instruction (MOI) certificate from English medium schools.</li>
        <li>12th Grade English Scores: Class 12th English scores can be considered as proof of proficiency.</li>
        <li>Online Interview: Certain universities may conduct interviews via platforms like Skype or Microsoft Teams.</li>
        <li>Other Exams: Some universities offer their own qualifying exams instead of IELTS, TOEFL, or PTE.</li>
        </ol>
        
        <h6>Documents Needed:</h6>
        
        <ol>
        <li>10th and 12th mark sheets</li>
        <li>Bachelor’s semester mark sheets, provisional certificate, and consolidated mark sheet</li>
        <li>Medium of Instruction (MOI) certificate, if required</li>
        <li>Passport, Letters of Recommendation, Resume, Statement of Purpose</li>
        <li>University offer letter, Letter of Experience (if applicable)</li>
        <li>Other academic transcripts</li>
        <li>Visa Requirements:</li>
        </ol>
        
        <p>For a Tier 4 UK student visa, English proficiency is required.</p>
        <ol>
        <li>Minimum band scores: Diploma (5.0), Bachelor’s (6.0), Master’s (6.0–6.5).</li>
        <li>If IELTS is waived, request confirmation from the university for visa eligibility.</li>
        </ol>`,
    },
    {
      heading: "Intakes",
      content: `<p>Intake Availability:<br/>
        Majority of programs are available in September and January.<br/>
        Minor intakes are offered in March, April, and May.</p>
        <h6>Admission Process:</h6>
        <p>Admission seekers should consult with our counselors for program details.<br/>
        After selecting the program and university, we will apply on behalf of the candidate to the respective university.</p>

        <h6>Offer Letter and Medical Test:</h6>
        <p>Once the university issues the offer letter, the aspirant must qualify for a medical test.</p>

        <h6>Financial Credibility:</h6>
        <p>After the medical test, the student must demonstrate financial credibility to obtain the CAS (Confirmation of Acceptance for Studies) letter.</p>

        <h6>Financial Requirements:</h6>
        <p><b>Outer London:</b> Tuition fees + living expenses of approximately £9,207 must be maintained in the student’s account for 28 days.</p>
        <p><b>Inner London:</b> Tuition fees + living expenses of approximately £12,006 must be kept in the student’s or their parent’s account for 28 days.</p>

        <h6>Visa and Travel:</h6>
        <p>After obtaining the visa, the student can fly to the UK to pursue their dream course.</p>



        <h6>Prominent scholarships</h6>
        <ol>
        <li>GREAT Scholarship: Supports international students to study in the UK.</li>
        <li>Chevening Scholarship: Funded by the UK government for outstanding students pursuing a master’s degree.</li>
        <li>Commonwealth Scholarships: For students from low and middle-income Commonwealth countries.</li>
        <li>Global Wales Scholarship: Funding for undergraduate and postgraduate programs in Wales.</li>
        <li>Fulbright Scholarship: Offers scholarships for Indian students to study in the UK.</li>
        <li>Sir Ratan Tata Scholarship: Financial assistance for Indian postgraduate students in social sciences and development studies.</li>
        </ol>`,
    },
  ],
};

countryDetails[3] = {
  name: "Study in France",
  image: coursesGrid3,
  link: "/study-in-france",
  pageTitle: "Study in France",
  pageDesc: `<h6>At a Glance</h6>
    <p>In terms of unique historical culture, geography and contributions to education, France remains one of the giants of Western European nations. With a specialization in educational excellence, the country welcomes non-French scholars from several continents. Students looking forward to studying engineering, arts and humanities, business, gastronomy as well as fashion, will have their needs well catered for in French institutions. The rich cultural life coupled with the desire for innovation and discovery typifies the frenzied academic environment. Furthermore, the safe and cosmopolitan nature of France serves as an asset in enrichening one both professionally and personally. In many ways, French culture captured us, but most of all the opportunity to study in France – we are glad to fulfill your wish and help you in each step of this process!</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul class="education-list-style-one">
        <li>Affordable and Quality Education: High standards at competitive prices.</li>
        <li>Multicultural Society: A diverse and inclusive environment.</li>
        <li>English-Taught Courses: Many universities offer programs in English.</li>
        <li>Attractive Tuition Fees: Cost-effective options for international students.</li>
        <li>Research and Development Opportunities: Excellent facilities and support for innovation.</li>
        <li>Available Scholarships: Various funding options to assist students.</li>
        <li>Government Accommodation Assistance: Support for securing housing.</li>
        <li>Travel Opportunities: An appealing destination for exploring Europe.</li>
        </ul>

        <h6>Requirements</h6>
        <p><b>Language of Instruction:</b> Courses are offered in both French and English.</p>
        <p><b>French Language Proficiency:</b> No need for a French language test for international students.</p>

        <h6>Academic Qualifications:</h6>
        <p><b>Bachelor’s Degree:</b> Minimum of 50% in 12th grade required.</p>
        <p><b>Master’s Degree:</b> Minimum of 50% in Bachelor’s degree required.</p>

        <h6>Foundation and Diploma Programs:</h6>
        <p>Students with around 50% can qualify for these programs.</p>
        <p><b>Age Requirement:</b> Students must be at least 18 years old before joining a degree program.</p>

        <h6>Mandatory Documentation:</h6>
        <p>MOI (Medium of Instruction) certificate must be submitted for enrollment.</p>
        <p>IELTS and French language proficiency are non-mandatory.</p>
        <h6>Intakes</h6>
        <p>Major intakes are available in February and September</p>`,
    },
    {
      heading: "Prominent scholarships",
      content: `<ul class="education-list-style-one">
        <li>Scholarships for International Students in France</li>
        <li>Charpak Scholarship Program</li>
        <li>Eiffel Scholarship Program of Excellence</li>
        <li>Erasmus+ Mobility Scholarships for Foreign Students</li>
        <li>French+ Sciences Scholarships</li>
        <li>Make Our Planet Great Again (MOPGA) Scholarships</li>
        <li>Legrand Empowering Scholarship</li>
        <li>Amba Damia Scholarship</li>
        <li>Emile-Boutmy Scholarship</li>
        <li>Accommodation Assistance </li>
        </ul>`,
    },
    {
      heading: "CAF (Caisse des Allocations Familiales):",
      content: `<ul>
        <li>Financial support provided by the French government to help with housing-related costs.</li>
        <li>Available to international students renting a flat, furnished rental, studio, or flat share.</li>
        <li>Eligible students can receive approximately €180 to €200 each month to assist with accommodation expenses.</li>
        </ul>
        `,
    },
  ],
};

countryDetails[4] = {
  name: "Study in Germany",
  image: coursesGrid4,
  link: "/study-in-germany",
  pageTitle: "Study in Germany",
  pageDesc: `<h6>At a Glance</h6>
    <p>Due to excellent quality education and advanced research, Germany remains among the most popular countries for international students. Consisting of 16 federal states, Germany provides comprehensive studies in Engineering, Medicine, Natural Sciences, Business, Technology, Arts, Architecture and other fields. Several eminent universities and technical colleges create a perfect academic milieu for their students by generating new ideas on the background of past. With relatively low tuition and high practical employment opportunities, Germany grows students from every corner of the world making it a great country to study.</p>

    <h6>The Upsides</h6>

    <p><b>Better Universities:</b> Germany bears some of the most prestigious universities that are famous for their best academic records.</p>
    <p><b>Different Degree Courses:</b> Students have the options to select different degree programs in various study fields.</p>
    <p><b>Globally Accepted Study Programs:</b> Degrees from German universities are widely accepted and recognized across the world.</p>
    <p><b>Affordability:</b> Cost of living in Germany is fairly better than many other countries for students.</p>
    <p><b>Flexible Travel Options:</b> Applying for a German student visa also provides you with a Schengen visa which means that you can visit 27 other European countries as well using the same visa.</p>
    <p><b>Part-time work:</b> Students have to complete their studies as well then they are headed for a glimpse of valuable work experience and supplementary in-cash support.</p>
    <p><b>Higher employability rate:</b> Studies show that there is a high probability of you being employed as against graduates from other countries.</p>
    <p><b>Diverse Community:</b> Germany is a country of multi-cultural These factors makes it easier to foreign students to adapt and feel welcome.</p>
    <p><b>Free language training:</b> Students have access to free courses in German, which can facilitate the daily life and their experience.</p>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<p><b>Higher Education Entrance Qualification:</b>
        A high school diploma, school-leaving certificate, or university entrance exam result is typically required for international and undergraduate students.</p>
        <p><b>Program-Specific Requirements:</b>
        You may need to meet additional specific requirements based on your chosen program.</p>
        <p><b>English Language Proficiency:</b>
        Proof of English proficiency may be required, typically through TOEFL or IELTS scores.</p>
        <p><b>German Language Proficiency:</b>
        Proficiency in German is non-mandatory for studying at colleges or universities in Germany.</p>`,
    },
    {
      heading: "Course Qualifications",
      content: `<p><b>Bachelor’s Degree:</b> Minimum of 12/13 years of education with at least 50% marks in the 12th grade.</p>
        <p><b>IELTS Requirement:</b> Overall score of 6.0, with no individual score lower than 6.0.</p>
        <p><b>Master’s Degree:</b> Bachelor’s degree with 50% and above.</p>
        <p><b>IELTS Requirement:</b> Overall score of 5.5, with no individual score lower than 6.0.</p>

        <h6>Duration</h6>
        <p><b>Undergraduate Degrees:</b> Typically take a minimum of 3 years.</p>
        <p><b>Postgraduate Degrees:</b> Usually require 2 years.</p>`,
    },
    {
      heading: "Intakes",
      content: `<h6>Summer Intake:</h6>
        <p>Application Period: March/April</p>
        <p>Tuition Fees: Approximately 10,236 Euros for the first year.</p>

        <h6>Winter Intake:</h6>
        <p>Application Period: September/October</p>
        <p>Certain private universities may have additional intakes in February, May, and June.</p>

        <h6>Prominent scholarships</h6>
        <ul class="education-list-style-one">
        <li>DAAD Scholarship Programs</li>
        <li>Erasmus Scholarship Programs</li>
        <li>Heinrich Böll Foundation Scholarships</li>
        <li>DeutschlandStipendium National Scholarship Programme</li>
        <li>Konrad-Adenauer-Stiftung Scholarships</li>
        <li>Bayer Foundation Awards</li>
        <li>International Ambassador Scholarship</li>
        <li>International Student Financial Aid (ISFA)</li>
        </ul>`,
    },
  ],
};

countryDetails[5] = {
  name: "Study in Dubai",
  image: coursesGrid5,
  link: "/study-in-dubai",
  pageTitle: "Study in Dubai",
  pageDesc: `<h6>At a Glance</h6>
    <p>Dubai is part of the largest and most populous metropolitan area in the United Arab Emirates (UAE), situated on the Persian Gulf and backed by the Arabian desert.Known for its skyscapers, high standard of living and its reputable higher education institutions, Dubai makes for a popular study destination. It is a truly cosmopolitan city, attracting students from all around the world.Strong post-study work prospects, a booming economy, tax-free income, easy visa policies and a growing job market are additional advantages for international students who wish to study in Dubai.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul class="education-list-style-one">
        <li>Afforadable cost</li>
        <li>Low travel costs and time</li>
        <li>Multicultural Experience</li>
        <li>IELTS is not mandatory</li>
        <li>Age gap and year gap accepted</li>
        <li>Opportunity to learn in the branch institutions of top universities in UK, USA, Australia and so on at lower cost</li>
        <li>Opportunity to visit or study on the mother campuses in different nations.</li>
        </ul>`,
    },
    {
      heading: "Academic Qualifications:",
      content: `<ul class="education-list-style-one">
        <li>Bachelor’s Degree: Minimum of 50% in 12th grade required.</li>
        <li>Master’s Degree: Minimum of 50% in Bachelor’s degree required.</li>
        <li>PG Diploma: Minimum of 50% in Bachelor’s degree required.</li>
        <li>Students with less than 50% marks for qualifying exams can attend foundation courses and it leads to securing their dream institutions with the course of their choice.</li>
        </ul>`,
    },
    {
      heading: "Intakes",
      content: `<p>Major intakes are available in Januray and September</p>
        <p>Modul University has an intake in December or February.</p>
        <p>Canadian University, Murdoch University and University of Wollongong has an intake in May along with Intakes of January and September.</p>
        <ul class="education-list-style-one">
        <li>Prominent scholarships</li>
        <li>UG Merit Scholarship</li>
        <li>Khalifa University Scholarships</li>
        <li>UAE University Full Scholarship</li>
        <li>Graduate Merit Scholarships</li>
        <li>Partial Tution Scholarship for International Students</li>
        <li>Chancellor Fellowship for Graduate Students at UAE University</li>
        </ul>`,
    },
  ],
};

countryDetails[6] = {
  name: "Study in Ireland",
  image: coursesGrid6,
  link: "/study-abroad-in-ireland",
  pageTitle: "Study in Ireland",
  pageDesc: `<h6>At a Glance</h6>
    <p>Out of all the countries in the world, Ireland is one of the countries which has great appreciation with regards to higher studies. Due to their rich history and resources, Irish universities provide cutting edge facilities and pro-education services developed by the greatest universities in the world. However, due to the concentration of the country’s government on the improvement of its education level, it has seen a rapid growth in the number of foreign students studying in Ireland.</p>
    <p>The Irish people have always considered it and have every reason to consider it as the best in international tourism, welcoming every visitor who sets foot in Ireland. It is one of the safest countries and we take it for granted that security is strictly enforced.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `<ul>
        <li>Education - Impressive and high quality education system. World's top universities for computer science are located in Ireland.</li>
        <li>Research - Universities in Ireland are among the top 1% research institutes in world.</li>
        <li>Scholarships - Government of ireland offers attractive scholarships for studying and research.</li>
        <li>COST - Ireland is one the most affordable country compared to other European study designations.</li>
        <li>Culture - Studying in Ireland offers a unique opportunity to immerse yourseld in tapestry of culture, history and artistic expression.</li>
        <li>Environment - Friendly and Supportive Environment.</li>
        <li>Industry - Students get offers from various innovative industries.</li>
        </ul>`,
    },
    {
      heading: "Classification of Irish Education System",
      content: `
      <ul class="education-course-detail-option">
      <li><h6>Universities</h6></li>
      <li><h6>Institutes</h6></li>
      <li><h6>Colleges</h6></li>
      </ul>
      <p>Universities in Ireland offer over 5000 courses in various subject areas at different study levels.</p>`,
    },
    {
      heading: "Popular programs in Ireland",
      content: `
      <ul class="education-course-detail-option">
      <li><h6>IT</h6></li>
      <li><h6>Pharmaceuticals</h6></li>
      <li><h6>Business</h6></li>
      <li><h6>Engineering</h6></li>
      <li><h6>Hotel Management</h6></li>
      </ul>
      <h6>Requirements</h6>
      Bachelors - Minimum of 50% in 12th grade required.
      IELTS Score- Overall-6 , Individual-5.5
      Many Irish Universities & Institutions offers honors Degree at the Bachelor's level.
      Master's - Minimum of 55% in Bachelor’s degree required.
      IELTS Score- Overall-6.5 , Individual-6


      <h6>Intakes</h6>

      <p>Major intakes are available in February and September to October.</p>

      <h6>Irish Education in My Career</h6>
      <p>Such educational systems are among the best in the world regarding the offer of courses and qualifications. At the end of certain courses, a few of these institution providers guaranteed employment. For study interval international students studying in Ireland can work part-time every week for 20 hours, as per the Irish government. When on holiday, international students are allowed to work for as much as forty hours per week. Upon course completion, the Bachelor’s degree holder is allowed to remain in Ireland for a maximum period of twelve months. The postgraduate students who studied in Ireland will also be eligible to remain in the country for a period of 2 years. After the stayback duration, a student can earn the least income set up employed by the government and be eligible for a Green Card application.</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Fullbright Scholarships</li>
      <li>Undergraduate Merit Scholarship</li>
      <li>Naughton Fellowship</li>
      <li>Government of Ireland Postgraduate Scholarships</li>
      <li>Scholarships for Women in Leadership & Management</li>
      <li>St. Vincent de Paul Scholarship</li>
      <li>NUI Gateway International Students Scholarships</li>
      <li>Central Bank of Ireland Undergraduate Scholarships for International Students</li>
      </ul>`,
    },
  ],
};

countryDetails[7] = {
  name: "Study in Malaysia",
  image: coursesGrid7,
  link: "/malaysia-study-abroad-agency",
  pageTitle: "Study in Malaysia",
  pageDesc: `<h6>At a Glance</h6>
    <p>Malaysia is a beautiful as well as geographical innovative nation is located in South-East Asia region between Thailand and Singapore.
    There is no doubt that Malaysia possesses a unique blend of cultural and geographical diversity. This country is a hub of business-as-usual and trade within the region of Southeast Asia. In recent years, Malaysia has positioned herself-up as the region’s hub of higher learning. In addition, the good standard of living, comparatively low cost of education and the diversity of the population makes Malaysia one of the most desirable places to study for foreigners.
    The system of education in Malaysia provides many international and national level programs and various areas of study.</p>`,
  pageContent: [
    {
      heading: "The Upsides",
      content: `
      <ul class="education-instructor-social">
      <li>Affordable tution fees & Low Living cost</li>
      <li>Multiethnic Population</li>
      <li>Melting pot of different cultures</li>
      <li>Student friendly laws for International Students</li>
      <li>Quality education</li>
      <li>Business and trade hub of South East Asia</li>
      <li>Diverse Courses</li>
      </ul>`,
    },
    {
      heading: "Requirements",
      content: "<p>IELTS or TOEFL score</p>",
    },
    {
      heading: "Academic Qualifications:",
      content: `<ul>
      <li>Diploma : Minimum of 60% in 10th or 12th grade required.</li>
      <li>Bachelor’s Degree: Minimum of 60% in 12th grade required.</li>
      <li>Master’s Degree: Minimum of 50% in Bachelor’s degree required.</li>
      <li>PG Diploma : Minimum of 60% in Bachelor’s degree required.</li>
      </ul>`,
    },
    {
      heading: "Intakes",
      content: `<p>Major intakes are in January & September. Additional intakes happens in May, July & November.</p>
      <ul>
      <li>Prominent scholarships</li>
      <li>Malaysian Government Scholarships</li>
      <li>Malaysia International Scholarship (MIS)</li>
      <li>The Malaysian Technical Cooperation Programme (MTCP) Scholarship</li>
      <li>Malaysia Commonwealth Scholarship and Fellowship plan</li>
      <li>United Nations University ligh Scholarship</li>
      <li>Education Future International Scholarship</li>
      </ul>`,
    },
  ],
};

countryDetails[8] = {
  name: "Study in USA",
  image: coursesGrid8,
  link: "/study-in-usa",
  pageTitle: "Study in USA",
  pageDesc: `<h6>At a Glance</h6>
    <p>It is no secret that the United States attracts students from every part of the world due to the strength and the diversity of its education systems With a range of programs as Engineering, Medicine, Business, Arts, Technology, and Humanities, the most US universities are focused not only on healthy scholarly expectations but also on practical approaches to the gained knowledge. Because of this, students benefit from practical classroom experience that makes them well-prepared for the work environment. From the most prestigious Ivy League schools to top public colleges, U.S. education is comprehensively aimed to embrace and facilitate individual and professional development and be an excellent place for people willing to achieve their educational purposes.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Globally Recognized Qualifications</li>
    <li>Flexible Learning Environment</li>
    <li>Opportunities for Interships</li>
    <li>Practical and Research-Oriented Programs</li>
    <li>Scholarships and Fellowships Available</li>
    <li>Technology-Driven Learning</li>
    <li>Part-Time Employment Options</li>
    <li>Post-Study Work Visa Opportunities</li>
    </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<h6>English Language Proficiency:</h6>
      <p>TOEFL and IELTS: - These examinations are required for all learners to prove their English competency for admission purposes.</p>

      <h6>Graduate Studies:</h6>
      <p>GRE:- The examination is very essential for graduate studies as it measures the knowledge of verbal reasoning, as well as quantitative reasoning and analytical writing skills.</p>

      <h6>Management Studies:</h6>
      <p>GMAT: – This examination is essential for an individual wishing to undertake an MBA and other postgraduate management training programmes. It assesses the analytical and writing capabilities, as well as problem solving skills.</p>

      <h6>Collegiate Programs:</h6>
      <p>SAT:- Test offered to undergraduate candidates seeking for degrees in bachelor’s studies with emphasis on reading, writing and maths skills.</p>

      <h6>Legal and Clinical Institutions:</h6>
      <p>LSAT:- Accepted by law schools as criterion for admission, scoring skills in critical thought, reading and reasoning.</p>
      <p>MCAT: - Also a qualifying examination for medical school, students are tested on knowledge of natural as well as social sciences and analytical reasoning skills.</p>


      <h6>Undergraduate programs candidates are required to pass</h6>

      <p>60% in their 12th subjects. (In engineering programmes, students are required to obtain very high grades in Mathematics, Physics and Chemistry subjects.)</p>

      <p>Average SAT Score = 1160 out of 1600.</p>

      <p>IELTS 6.5 Overall, and each component 6.0. Some institutions prefer an overall 6.0 and individuals a 5.5</p>

      <p>Should the student wish to enroll in bachelor’s programs in the United States of America, tuition expenses extending from 20,000 USD to 35,000 USD must be envisaged. The standard length of studies comprising a bachelor’s degree is 4 years. Living expenses stand at approximately 16000 usd.</p>


      <h6>Intakes</h6>
      <p>Majority of programs are available in January and August</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Fulbright – Nehru Fellowships</li>
      <li>Chicago Booth Scholarships for Indian Students</li>
      <li>Global Students Scholarship</li>
      <li>National Overseas Scholarship</li>
      <li>SN Bose Scholarship</li>
      <li>Narotam Sekhsaria Scholarship</li>
      <li>Hubert Humphrey Fellowship Program</li>
      <li>AAUW International Fellowships</li>
      </ul>`,
    },
  ],
};

countryDetails[9] = {
  name: "Study in Australia",
  image: coursesGrid9,
  link: "/study-abroad-in-australia",
  pageTitle: "Study in Australia",
  pageDesc: `<h6>At a Glance</h6>
  <p>Australia is known for providing the best education and indulging in modern research, and its appeal specifically to the cosmopolitan learners is highly commendable. Most Australian universities are well rounded and well diversified, offering programs in Engineering, Medicine, Business, Technology, Arts, Hospitality, Environmental studies among others. In addition, The comparatively low cost of education, good links to the industries, and the availability of many internships and jobs make Australia easily appealing to many students from all over the globe. In addition, the beautiful and multicultural environment of the country adds on to the favorable experience of studying there. We will help you achieve your goal of studying in Australia by offering you specialized assistance and advice!</p>

  <h6>The Upsides</h6>
  <ul>
  <li>Quality Education: World-class academic standards.</li>
  <li>Extended Stay: 4-5 years post-study stay.</li>
  <li>Internships: Abundant hands-on experience opportunities.</li>
  <li>Employment Prospects: High job demand for graduates.</li>
  <li>Top Universities: Several institutions ranked globally.</li>
  <li>Scholarships: Financial support options available.</li>
  <li>Part-Time Jobs: Flexible work opportunities for students.</li>
  <li>Visa Success: 100% success rate for eligible applicants.</li>
  </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<h6>Bachelor’s Degree:</h6>
      <p>Qualifications: Completion of 12th grade with 50% and above.</p>
      <p>IELTS Score: Minimum of 6.5.</p>
      <p>PTE Score: Minimum of 50.</p>

      <h6>Master’s Degree:</h6>
      <p>Qualifications: Bachelor’s degree with 50% and above.</p>
      <p>IELTS Score: Minimum of 6.5.</p>
      <p>PTE Score: Minimum of 50.</p>

      <h6>Foundation and Pre-Master’s Courses:</h6>
      <p>Available for students who do not meet the required qualifications.</p>
      <p>By Completing these courses can help gain admission to desired programs.</p>

      <h6>Intakes</h6>
      <p>Major intakes are available in February,July and November</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Australia Award Scholarship</li>
      <li>Global Excellence Scholarship</li>
      <li>Australian Government Research and Training Program (AGRTP)</li>
      <li>Destination Australia International Scholarship</li>
      <li>Rotary Peace Fellowship</li>
      <li>STEM Scholarship for Southeast Asian Students</li>
      </ul>`,
    },
  ],
};

countryDetails[10] = {
  name: "Study in Singapore",
  image: coursesGrid10,
  link: "/study-in-singapore",
  pageTitle: "Study in Singapore",
  pageDesc: `<h6>At a Glance</h6>
    <p>Singapore also commonly known as Little Red Dot has penetrated the domestic as well as the international scene in several ways.</p>

    <p>Overlooking this metropolitan city, there are a million reasons to appreciate knowing this garden city such as its thoroughly and amicably organized urban system and swift economic growth and rated among the top in safety in the entire world.</p>

    <p>The growth of this small island has been boosted by the education system in the island country which is also highly regarded by the Government of Singapore. It is not surprising therefore that the people of Singapore are well educated and possess great skills amongst other attributes. A notable institute in this regard, the National University of Singapore (NUS) founded in 1905 has within six years persistently rated the top three tertiary institutions in Asia since 2013.</p>

    <h6>The Upsides</h6>
    <ol>
    <li>Affordable education with low cost of living</li>
    <li>Safe city with low crime rate and stable political institution</li>
    <li>Diverse Population</li>
    <li>Multilingual Country</li>
    <li>High Standard of Living</li>
    <li>Excellent Job Opportunities</li>
    <li>Countless Courses</li>
    </ol>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<p>Diploma : Minimum pass in 10th or 12th grade required.</p>
      <p>Bachelor’s Degree: Minimum pass in 12th grade required.</p>
      <p>Master’s Degree: Minimum pass in Bachelor’s degree required.</p>
      <p>PG Diploma : Minimum pass in Bachelor’s degree required.</p>

      <h6>Mandatory Documentation:</h6>
      <p>IELTS is mandatory for students not having MOI (Medium of Instruction not English)
      IELTS with overall band score of minimum 6 and individual score of minimum 5.5</p>

      <h6>Intakes</h6>
      <p>Student intakes varies according to institutions and some institutes have rolling intakes.</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Singapore International Graduate Award</li>
      <li>Singapore Government Scholarship</li>
      <li>Global Merit Scholarship</li>
      <li>Commonwealth Scholarship for Integrated Science</li>
      <li>Singapore Millenium Foundation Scholarship</li>
      <li>SIA Youth Scholarship</li>
      </ul>`,
    },
  ],
};

countryDetails[11] = {
  name: "Study in New Zealand",
  image: coursesGrid11,
  link: "/study-in-new-zealand",
  pageTitle: "Study in New Zealand",
  pageDesc: `<h6>At a Glance</h6>

    <p>Are you considering pursuing advanced studies in stunning countries with excellent academic institutions? Are you considering New Zealand as your study destination? New Zealand's educational institutions provide diverse academic programs, accommodating students at all levels. The educational program in New Zealand is designed to improve students' ability to analyze and their global perspective, resulting in a strong need for graduates in the worldwide job market. Moreover, New Zealand stands out as a breathtaking destination with inspiration in every aspect. At Guidance Plus, a top education consultancy for New Zealand, you can be assured that we will help you achieve your study abroad goals.</p>

    <h6>The Upsides</h6>

    <ul>
    <li>Home to eight globally renowned universities.</li>
    <li>Offers a wide range of academic programs.</li>
    <li>Known for educational excellence.</li>
    <li>Provides immense post-study work opportunities.</li>
    <li>Offers internships and placements.</li>
    <li>Universities are government-owned.</li>
    <li>Plenty of part-time work opportunities.</li>
    <li>Enormous chances for cutting-edge research and innovation.</li>
    </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<p>Entry requirements for studying in New Zealand include achieving a minimum academic score of 50 - 60%. Furthermore, different courses have specific IELTS requirements. Bachelor’s, master’s, graduate diploma, PG diploma, and graduate certificate programs are all open to international students. The PG diploma or bachelor degree or Master degree courses are categorized under level 7 programs. The duration of the PG diploma is one year, and completing this course provides a 3-year stay-back period. Opting for a course from the green list increases the chances of obtaining permanent residency.</p>

      <h6>Bachelor’s</h6>
        <ul>
        <li>Academic Score: Minimum 60% marks in 12th</li>
        <li>IELTS: Overall: 6 Individual: 5.5</li>	
        <li>PTE: 50</li>
        </ul>
      <h6>Master’s</h6>
      <ul>
        <li>Academic Score: Minimum 50% marks for Bachelor’s	</li>
        <li>IELTS: Overall: 6.5 Individual: 6	</li>
        <li>PTE: 64</li>
        </ul>
      <h6>Graduate Diploma/Graduate Certification Program</h6>
        <ul>
        <li>Academic Score: Minimum 60% marks in 12th	</li>
        <li>Overall: 6 Individual: 6	</li>
        <li>PTE: 50</li></ul>
      <h6>PG Diploma</h6>	
      <ul>
        <li>Academic Score: Minimum 60% marks in Diploma/Bachelor’s	</li>
        <li>IELTS: Overall: 6.5 Individual: 6	</li>
        <li>PTE: 60</li>
        </ul>
        
      <p>Looking to understand the entry criteria for getting admitted to a prestigious university in New Zealand? Connect now with the top New Zealand education consultancy, Guidance Plus, for expert advice and support.</p>

      <h6>Admission Processes and Enrollment: </h6>
      <p>The usual intakes in New Zealand are in the months of February and July though few universities also have intakes in September and November. At Guidance Plus, our advisors will assist you on which program and university is appropriate. Once you've chosen your preferred university, we'll support you in finalizing your application. Once you've been accepted, you'll be required to undergo a medical examination and demonstrate financial stability to obtain your enrollment. Guidance Plus has over a decade of experience in international education and a flawless track record of securing visas for all applicants.</p>
      <p>New Zealand's education system follows a framework that aligns with international standards, ensuring that a degree or diploma from a New Zealand institution is globally recognized and esteemed.</p>


      <h6>Intakes</h6>
      <p>Major intakes are available in February, July and October</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>New Zealand Excellence Awards (NZEA)</li>
      <li>Palmerston North City Mayor's Goodwill Ambassador Scholarship</li>
      <li>New Zealand Commonwealth Scholarships</li>
      <li>SEG Scholarship</li>
      <li>Eamon Molloy Memorial Scholarship</li>
      <li>Victoria Master’s Scholarship</li>
      <li>A C Rayner Memorial Scholarship</li>
      <li>The University of Waikato Excellence Scholarship for Asia</li>
      </ul>`,
    },
  ],
};

countryDetails[12] = {
  name: "Study in Sweden",
  image: coursesGrid12,
  link: "/study-in-sweden",
  pageTitle: "Study in Sweden",
  pageDesc: `<h6>At a Glance</h6>
    <p>The Kingdom of Sweden is the official name of Sweden and it is perched further into the northern region of Europe.</p>
    <p>Sweden is a nation of many surrounding seas, thousands of islands and indifferent plains with innumerable internal lakes alongside boreal forests swathed with glacial mountains. To the west, it is bordered by Norway and to the East Finland while to the southwest it has a bridge-tunnel that connects it to Denmark across the Oresund Straits.</p>
    <p>Sweden is a very advanced country, being on the 6th place, according to the Human Development Index.</p>
    <p>Another distinction would be the way one studies in Sweden, which is completely different than studying anywhere else since Swedish universities have an open atmosphere with advanced pack work. The emphasis of the education system of Sweden is rather on your personal interests and passions than making you try to get a certain mark.</p>

    <h6>The Upsides</h6>
    <ul>
    <li>Affordable and Quality Education</li>
    <li>English-Taught Courses</li>
    <li>Easy Admission Process</li>
    <li>High Visa Success Ratio</li>
    <li>Technically strong Country in Innovation</li>
    <li>Industry Centric Courses</li>
    <li>Liberal Scholarships</li>
    <li>Excellent International Study Environment</li>
    </ul>`,
  pageContent: [
    {
      heading: "Requirements",
      content: `<ul>
        <li>STEM Graduates - IELTS/GRE is exempted.</li>
        <li>Management Studies - IELTS based on University requirement</li>
        </ul>

        <h6>Academic Qualifications:</h6>

        <p>Bachelor’s Degree: Minimum of 60% in 12th grade required.</p>
        <p>IELTS - Overall band-6 Individual - 5.5</p>

        <p>Master’s Degree: Minimum of 60% in Bachelor’s degree required.</p>
        <p>IELTS - Overall band-6.5 Individual - 6</p>



        <h6>Intakes</h6>
        <p>Mainly two intakes in the month of January & August</p>

        <ul class="education-list-style-one">
        <li>Prominent scholarships</li>
        <li>Swedish Institute Scholarships for Global Professionals (SISGP)</li>
        <li>Lund University Global Scholarship</li>
        <li>Karolinska Institute Glabal Master's Scholarships</li>
        <li>Erasmus Mundus Joint Masters Scholarships</li>
        <li>Chalmers IPOET Scholarships</li>
        </ul>`,
    },
  ],
};

countryDetails[13] = {
  name: "Study in Finland",
  image: coursesGrid13,
  link: "/study-in-finland",
  pageTitle: "Study in Finland",
  pageDesc: `<h6>At a Glance</h6>
    <p>Education in Finland is known across the globe and pursuing studies in this country is highly interesting and enables one to acquire new knowledge and change the outlook on the teaching methods while actively participating in student life in a safe environment. Finnish institutions are more research oriented and place emphasis on the students activities.</p>
    <p>The supporters of Finland offer quality education, do a lot for students, allowing them to focus on research and hence it becomes an ideal place for students. Most of the universities and their teachers in Finland encourage the students and their expertise is quite useful.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Affordable and Quality Education</li>
    <li>High Standard of Living</li>
    <li>Easy Admission Process</li>
    <li>IELTS not Mandatory</li>
    <li>Strong Research and Innovation Culture</li>
    <li>Healthcare and social welfare system is good</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 60% in 12th grade required.</p>
        <p>IELTS (not mandatory) - Overall band score - 6  individual score - 5.5</p>

        <p>Master’s Degree: Minimum of 60% in Bachelor’s degree required.</p>
        <p>IELTS (not mandatory) - Overall band score - 6  individual score - 5.5</p>

        <h6>Intakes</h6>
        <p>There are 2 major intakes for international students. The intakes are in February and September.</p>

        <ul class="education-list-style-one">
        <li>Prominent scholarships</li>
        <li>ERCIM Alain Bensoussan Fellowship Program</li>
        <li>Education Future International Scholarship</li>
        <li>Dr.Eduard Gubelin Research Scholarship</li>
        <li>Higher Education Scholarship Test for Indian Students HEST</li>
        <li>ACI Foundation Richard D.Stehly Memorial Scholarships</li>
        <li>K C Mahindra Scholarships for Post-Graduate Studies Abroad</li>
        </ul>`,
    },
  ],
};

countryDetails[14] = {
  name: "Study in Denmark",
  image: coursesGrid14,
  link: "/study-in-denmark",
  pageTitle: "Study in Denmark",
  pageDesc: `<h6>At a Glance</h6>
    <p>Denmark has been mesmerizing tourists for centuries owing to its breathtaking landscape, embracing culture and immaculate coastlines.
    With over a thousand years of history and culture, as well as vast picturesque Scandinavian scenery to visit, each one of your moments as an international students in Denmark will surely be great fun and one you will forever treasure.
    Due to the overall well-being of citizens, the efficiency of the educational process and beautiful nature it is very comfortable to study in this country.
    Denmark owns a well renounced education system which prioritizes student centered approaches and inter disciplinary learning.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Top ranked universities</li>
    <li>Numerous grants and scholarships</li>
    <li>Reasonable living costs</li>
    <li>Key Industries</li>
    <li>Happiest Country in World</li>
    <li>Top rated academic collaborations</li>
    <li>Global Outlook</li>
    <li>Multicultural Environment</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 60% in 12th grade required.</p>
      <p>IELTS - Overall band score with minimum 6.0</p>

      <p>Master’s Degree: Minimum of 60% in Bachelor’s degree required.</p>
      <p>IELTS - Overall band score with minimum 6.5</p>

      <p>MBA Degree: Minimum of 60% in Bachelor's degree & minimum of 2years management experience.</p>
      <p>IELTS - Overall band score with minimum 6.5</p>

      <h6>Intakes</h6>
      <p>There are 2 intakes in Danish Universities. They are from January to March and July to September.</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Erasmus Mundus Scholarships</li>
      <li>Nordplus Scholarship</li>
      <li>Danish Government Scholarship(Ministry of Higher Education and Science)</li>
      <li>Danish State Tution Fee Waivers and Scholarships at Roskilde University</li>
      <li>Fulbright Denmark</li>
      <li>The Lendo Scholarship</li>
      <li>K C Mahindra Scholarships for Post-Graduate Studies Abroad</li>
      <li>Education Future International Scholarship</li>
      </ul>`,
    },
  ],
};

countryDetails[15] = {
  name: "Study in Malta",
  image: coursesGrid15,
  link: "/study-in-malta",
  pageTitle: "Study in Malta",
  pageDesc: `<h6>At a Glance</h6>
    <p>The Republic of Malta is an island nation in the Mediterranean Sea, situated to the south of Italy and North of Libya. Three islands are inhabited including some islands which impress visitors by their culture, historical places and natural beauty. Malta is renowned for its prehistoric architectural sites that date back over eight thousand years with the oldest megalithic temples being older than ancient Egypt’s pyramid and the Stonehenge.</p>
    <p>Malta is situated in the Mediterranean and being a small country it has a great history, beautiful landscapes, the best educational institutions in the world, and more. Moreover, the archipelago also houses one of the oldest and leading universities in the Mediterranean along with few foreign educational bodies like GBS Malta. This has made it a hot spot for overseas students who wish to study in Malta.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>High Quality Education</li>
    <li>English-Taught Courses</li>
    <li>Safe Study Destination</li>
    <li>Exciting Career Opportunities</li>
    <li>Education Tax benefits</li>
    <li>Low Budget Country</li>
    <li>Part time job Opportunities</li>
    <li>Diverse Courses</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 50% in 12th grade required.</p>
      <p>IELTS - Overall Score - 6, Individual Score - 5.5</p>

      <p>Master’s Degree: Minimum of 50% in Bachelor’s degree required.</p>
      <p>IELTS - Overall Score - 6.5, Individual Score - 6</p>

      <h6>Intakes</h6>
      <p>Mainly intakes are availiable in months of February, May, June and October.</p>
      <p>Some Universities in Malta also intakes students in January, April and September.</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Erasmus Mundus Scholarship</li>
      <li>Education Future International Scholarship</li>
      <li>ACI Foundation Richard D.Stehly Memorial Scholarships</li>
      <li>ServiceScape Scholarship</li>
      <li>Rotary Foundation Global Scholarship Grants for Development</li>
      <li>Richard A.Freund International Scholarship</li>
      </ul>`,
    },
  ],
};

countryDetails[16] = {
  name: "Study in Estonia",
  image: coursesGrid16,
  link: "/study-in-estonia",
  pageTitle: "Study in Estonia",
  pageDesc: `<h6>At a Glance</h6>
    <p>Are you considering pursuing your further education in Estonia? Are you curious to find out more about your overseas study options? Guidance Plus is available to assist. We have over ten years of experience and are experts at assisting students just like you in navigating the challenges of studying in Estonia. Our counsellors are prepared to offer you whatever information you require, including information on course alternatives, college options, cost schedules, and application procedures. Make your dream of attending university in Estonia a reality by getting in touch with us for individualized advice!</p>
    <h6>The Upsides</h6>
    <p>There are approximately 160 study programs in English available for international students in Estonia. Doctoral candidates can pursue their studies at different European universities in four main regions: the Baltic area, the Tallinn region, and the Latvian region of the Russian Federation. This arrangement gives students convenient access to a range of financial assistance options. Estonia is also considered one of the best places to live and settle, thanks to its high standard of living and pristine environment.</p>
    `,
  pageContent: [
    {
      heading: "Benefits of Studying in Estonia",
      content: `<ol>
        <li>Programs offered in English</li>
        <li>Free Internet Access</li>
        <li>Cost-effective tuition and living expenses</li>
        <li>Easy Admission Process</li>
        <li>High success rate for visa applications</li>
        <li>No mandatory IELTS requirement</li>
        <li>Schengen Group Country</li>
        </ol>

        <p>If Estonia is your preferred destination for further studies, having essential information readily available is vital. Our Estonia Education Consultants are here to provide you with a brief overview of the country, its education system, and other relevant details to help you make well-informed decisions.</p>


        <h6>Entry Requirements to study in Estonia</h6>

        <p>Universities have both general requirements and specific criteria for admissions. All higher education institutions must follow the established guidelines.</p>

        <ol>
        <li>IELTS: Overall – Overall band score of 6.0, with a minimum of 5.5 in each band</li>
        <li>TOEFL: Minimum score of 72</li>
        <li>Previous education: At least 50% of previous education history</li>
        </ol>

        <p>Contact Guidance Plus, the No.1 Estonia study abroad consultants, to learn more about the entry requirements.</p>

        <h6>Documents Required</h6>

        <ol>
        <li>A copy of passport or ID card</li>
        <li>A passport picture</li>
        <li>Statement of purpose</li>
        <li>Copies of obtained secondary school diplomas, certificates, and grade lists</li>
        <li>Transcript of records</li>
        <li>Proof of English language proficiency</li>
        </ol>

        <p>With the support of Guidance Plus, you can choose the perfect program and college that match your interests and skills. Our experienced education specialists will guide you through the entire application process for an Estonian student visa. As the leading Estonian education consultant, we take pride in helping young people achieve their dreams.</p>


        <h6>Prominent scholarships</h6>

        <p>Explore the wide range of scholarships offered for studying in Estonia, which are primarily supported by public funding. International students with a valid passport are eligible to apply for these scholarships.</p>

        <h6>Most Demanded UG/PG Programmes</h6>
        <ol>
        <li>Computer Security</li>
        <li>Networking</li>
        <li>IT</li>
        <li>Cyber Security</li>
        <li>Digital Applications</li>
        </ol>

        <h6>Universities recommended by us</h6>
        <ol>
        <li>Tallinn University</li>
        <li>Tallinn University of Technology</li>
        <li>Estonian University of Life Sciences</li>
        <li>Estonian Entrepreneurship of Applied Sciences</li>
        <li>Tartu Health Care College</li>
        <li>TTK University of Applied Sciences</li>
        <li>Estonian Business School</li>
        <li>Estonian Information Technology College</li>
        <li>Pallas University of Applied Sciences</li>
        <li>Euroacademy</li>
        <li>University of Tartu</li>
        </ol>`,
    },
  ],
};

countryDetails[17] = {
  name: "Study in Lithuania",
  image: coursesGrid17,
  link: "/study-in-lithuania",
  pageTitle: "Study in Lithuania",
  pageDesc: `<h6>At a Glance</h6>
    <p>Lithuania boasts a well-developed education system which unites ancestral traditions and modernity in every possible way. Considerable number of English programs is offered to students and includes various degrees from engineering and IT to business and humanities. In addition to becoming a research institution, Lithuania has a vibrant startup ecosystem where students can get hands-on experience and build skills necessary for working in an international environment.</p>
    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>Affordable fees & Reasonable living expenses</li>
    <li>High quality education with wide variety of courses</li>
    <li>High quality of life</li>
    <li>Welcoming Environment & Friendly People</li>
    <li>Multicultural Environment with diverse population</li>
    <li>Work Opportunities</li>
    </ul>`,
  pageContent: [
    {
      heading: "Required Tests",
      content: `<p>IELTS - Overall band score of 5.5-6.5</p>
      <p>TOEFL - Score of 65-105</p>

      <h6>Intakes</h6>
      <p>Major intakes are available in September</p>

      <h6>Part-Time Job</h6>
      <p>International students are allowed to work part time jobs while they are enrolled in classes. No limitations for European Union Students while Non EU students are permitted to work for 20hours a week during school year and full time during summer.</p>

      <p>Prominent scholarships</p>
      <p>Erasmus+ Programme</p>
      <p>Lithuanian State Scholarship Programme</p>`,
    },
  ],
};

countryDetails[18] = {
  name: "Study in Netherlands",
  image: coursesGrid18,
  link: "/study-in-netherlands",
  pageTitle: "Study in Netherlands",
  pageDesc: `<h6>At a Glance</h6>
    <p>Holland, also known as The Kingdom of Netherlands, is a small northern European country with the North sea to the north. It is one of the Low Countries or Benelux countries with Belgium and Luxembourg. The region is predominantly known for its flat terrain, decorated with networks of waterways, tulip gardens, wind turbines and highways specially designated for bicycles. The official capital is Amsterdam while the center of the government is in the Hague.</p>
    <p>Holland boasts one of the best higher education systems which in addition to high degrees also offers scientific and engineering courses with much Employment opportunities.</p>
    <p>Holland boasts of beautiful country sides and vibrant cities filled with strings of art galleries, cafes and historical places which can all be tour on a bicycle. To boot, cheap flights to bus and train direct to other countries in Europe mean that whilst staying in Holland you can take inexpensive trips to countries such as Germany and France.</p>

    <h6>The Upsides</h6>
    <ul>
    <li>High Quality Education </li>
    <li>Affordable living Expenses</li>
    <li>Diverse Population</li>
    <li>English taught Courses</li>
    <li>IELTS not mandatory</li>
    <li>Cities with best quality of infrastructure</li>
    <li>Internationaly recognized courses</li>
    </ul>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Minimum of 75% in 12th grade required.</p>
      <p>IELTS - Minimum band of 6 </p>
      <p>TOEFL- Score of 550</p>

      <p>Master’s Degree: Completion of 3-year bachelor degree in arts, science or commerce.</p>
      <p>IELTS - Minimum band of 6 </p>
      <p>TOEFL- Score of 550</p>

      <h6>Intakes</h6>
      <p>Major intakes are available in February and September</p>

      <h6>Prominent scholarships</h6>
      <p>NL Scholarship (Holland Scholarship)</p>`,
    },
  ],
};

countryDetails[19] = {
  name: "Study in Poland",
  image: coursesGrid19,
  link: "/study-in-poland",
  pageTitle: "Study in Poland",
  pageDesc: `<h6>At a Glance</h6>
    <p>Poland, being in Central Europe, has a population of approximately 37.96 million people. Different countries border Poland, Germany on the west, the Czech Republic and Slovakia on the south, Ukraine and Belarus to the east and Lithuania, Latvia and The Baltic Sea on its northern side. Poland is a nation that has existed for centuries, as there have been human presence since the Paleolithic Age.</p>
    <p>Poland has lots to offer to international students. Aside from notable universities and promising job prospects, Poland affords a much reasonable living standard in comparison with other European countries, and therefore, it is ideal for students on a budget.</p>
    <p>Polish Universities have an impeccable education quality. Teaching staff holds a European accreditation level and the universities offer study programs from medicine and engineering to art and social sciences.</p>

    <h6>The Upsides/<h6>
    <ul class="education-list-style-one">
    <li>Affordable tution fees & Living Cost</li>
    <li>Wide range of English taught Courses</li>
    <li>Availiable Scholarships</li>
    <li>Young Innovators</li>
    <li>Internationally recognized Courses</li>
    <li>IELTS not mandatory</li>
    <li>Friendly and Supportive Environment</li>
    </ul>`,
  pageContent: [
    {
      heading: "Mandatory Documentation",
      content: `<p>Proof for English Language Competency must be submitted for enrollment.</p>
      <p>IELTS and TOEFL are non-mandatory.</p>

      <ul>
      <li>Educational Structure</li>
      <li>Bachelor's Degree</li>
      <li>Master's Degree</li>
      <li>Doctor of Philosophy</li>
      </ul>

      <h6>Intakes</h6>
      <p>Major intakes are available in February and September</p>


      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>Polish Government Scholarship Programme</li>
      <li>The Banach Scholarship Programme</li>
      <li>The Konstanty Kalinowski Scholarship Programme</li>
      <li>Lukasiewicz Scholarship Programme</li>
      <li>University-Specific Scholarships</li>
      </ul>`,
    },
  ],
};

countryDetails[20] = {
  name: "Study in Norway",
  image: coursesGrid20,
  link: "/study-in-norway",
  pageTitle: "Study in Norway",
  pageDesc: `<h6>At a Glance</h6>
    <p>Norway is a country in the Scandinavian peninsula located in the western region of the region. Its long coastline and admirable nature reserves form parts of its outstanding tourist attractions in Europe. The universal characteristics of its climatic conditions include harsh subarctic climes in the northern regions and mild temperate southern climatic conditions. It is worth to note that about three fifth of Norway is a hilly land which gives way to exquisite bays and natural beaches, all ideal for outdoor fun.</p>
    <p>In spite of its small size, Norway contains various university institutions, university colleges and other higher education schools. This makes the Norwegian cases more advanced compared with American ones in terms of education.</p>
    <p>In the university, students enjoy the relaxed atmosphere on the campus, friendly teachers etc.</p>

    <h6>The Upsides</h6>
    <ul class="education-list-style-one">
    <li>High Quality education</li>
    <li>Healthy work life balance</li>
    <li>Diverse and inclusive Environment</li>
    <li>Internationally recognized Courses</li>
    <li>One of World's happiest Countries</li>
    <li>Easy Admission Process</li>
    <li>High Innovative Job Opportunities</li>
    </ul>
    
    <h6>Language Requirements</h6>
    <p>TOEFL, IELTS, PTE, MOI are accepted</p>`,
  pageContent: [
    {
      heading: "Academic Qualifications",
      content: `<p>Bachelor’s Degree: Above 70% in 12th grade required.</p>
      <p>Master’s Degree: Above 70% in Bachelor’s degree required.</p>

      <h6>Part Time</h6>
      <p>Students in graduate and diploma programmes have an option of part time work of 40hours per week.</p>

      <h6>Stay Back</h6>
      <ol>
      <li>Bachelor’s Degree: 2 years</li>
      <li>Master’s Degree & PhD: Upto 4 years</li>
      </ol>

      <h6>Intakes</h6>
      <p>There is only a single intake and most of the intake occurs between December and March.</p>

      <ul class="education-list-style-one">
      <li>Prominent scholarships</li>
      <li>SCAR Fellowships in Antarctic Research</li>
      <li>Microsoft Research PhD Fellowship</li>
      <li>Mobility Grant for Norwegian Language nd Literature</li>
      <li>The ERASMUS+ Grant</li>
      <li>Wilhelmsen Foundation Scholarship Programme</li>
      <li>BI Presidential Scholarship</li>
      <li>NTNU Scholarship</li>
      <li>Scholarship Grant for Undergradute Students</li>
      <li>The $2000 "Privacy Matter" College Scholarship</li>
      <li>CLIFF-GRAD Scholarship (PhD)</li>
      <li>Become JACK and JONES Scholar (Masters and PhD)</li>
      </ul>`,
    },
  ],
};
export const COUNTRY_DETAILS = countryDetails;
